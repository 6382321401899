import React from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation

function Home() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen bg-white text-black font-sans">
      {/* Header */}
      <header className="border-b border-gray-200 py-6 text-center">
        <img
          src={`${process.env.PUBLIC_URL}/FDLogoBlack.png`}
          alt="FanDrive Logo"
          className="mx-auto mb-4"
          style={{ width: '300px' }} // Adjust size as necessary
        />
      </header>

      {/* Main Content */}
      <main className="flex-grow container mx-auto px-4 py-8">
        {/* Intro Card */}
        <div className="border border-gray-200 rounded-md p-6 mb-8 shadow-sm hover:shadow-md transition">
          <h2 className="text-3xl font-semibold mb-4">How FanDrive Works</h2>
          <p className="text-lg mb-4">
            Use your phone's camera to scan the QR code on any car to unlock exclusive driver details, car specs, and videos.
          </p>
          <hr className="border-gray-300 mb-4" />
        </div>

        {/* Steps */}
        <div className="border border-gray-200 rounded-md p-6 mb-6 shadow-sm hover:shadow-md transition">
          <h3 className="text-2xl font-bold mb-2">Step 1: Open Your Camera</h3>
          <p className="text-base">
            Point your phone's camera at the QR code on the car.
          </p>
        </div>
        <div className="border border-gray-200 rounded-md p-6 mb-6 shadow-sm hover:shadow-md transition">
          <h3 className="text-2xl font-bold mb-2">Step 2: Tap the Link</h3>
          <p className="text-base">
            Once the QR code is scanned, tap the link to see the car and driver details.
          </p>
        </div>
        <div className="border border-gray-200 rounded-md p-6 shadow-sm hover:shadow-md transition">
          <h3 className="text-2xl font-bold mb-2">Optional: Create an Account</h3>
          <p className="text-base">
            Create a free account to build your own car profiles to share—or to save your favorite cars and drivers.
          </p>
        </div>

        {/* Action Buttons */}
        <div className="mt-10 flex flex-wrap justify-center gap-4">
          <button
            onClick={() => navigate('/login')}
            className="bg-[#C29E32] text-white py-2 px-6 rounded-full shadow-md hover:shadow-lg hover:bg-opacity-90 transition"
          >
            Log In
          </button>
          <button
            onClick={() => navigate('/signup')}
            className="bg-[#C29E32] text-white py-2 px-6 rounded-full shadow-md hover:shadow-lg hover:bg-opacity-90 transition"
          >
            Sign Up
          </button>
          {/* Uncomment if/when you want to reintroduce the Create Profile CTA
            <button
              onClick={() => navigate('/create-profile')}
              className="bg-[#C29E32] text-white py-2 px-6 rounded-full shadow-md hover:shadow-lg hover:bg-opacity-90 transition"
            >
              Create Driver Profile
            </button>
          */}
        </div>
      </main>

      {/* Footer */}
      <footer className="border-t border-gray-200 py-6 text-center">
        <p className="text-sm">
          Powered by FanDrive &copy; 2025
        </p>
      </footer>
    </div>
  );
}

export default Home;
