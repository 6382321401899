import React, { useState } from 'react';
import { auth } from './firebase'; // Firebase Auth setup
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/user-profiles'); // Redirect after login
    } catch (error) {
      setError('Failed to log in. Please check your credentials.');
    }
  };

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setResetMessage('Password reset email sent! Check your inbox.');
    } catch (error) {
      setError('Error sending password reset email. Ensure your email is correct.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="w-full max-w-md bg-gray-800 p-8 rounded-lg shadow-md">
        {/* Logo */}
        <div className="flex justify-center mb-6">
          <img src={`${process.env.PUBLIC_URL}/FDLogoWhite.png`} alt="FanDrive Logo" className="h-16 w-auto" />
        </div>

        {error && <p className="text-red-500 mb-4">{error}</p>}
        {resetMessage && <p className="text-green-500 mb-4">{resetMessage}</p>}

        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mb-4 w-full p-3 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
        />
        <input
          type="password"
          placeholder="Enter your Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mb-4 w-full p-3 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
        />

        <button
          onClick={handleLogin}
          className="w-full bg-blue-500 text-black font-bold py-3 rounded-lg hover:bg-blue-600"
        >
          Log In
        </button>

        <button
          onClick={handleForgotPassword}
          className="mt-4 text-sm text-yellow-500 hover:underline"
        >
          Forgot Password?
        </button>
      </div>
    </div>
  );
}

export default LoginPage;
