import React, { useEffect, useState } from 'react';
import { auth, db, storage } from './firebase'; // Firebase setup
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  getDoc,
  updateDoc,
  orderBy,
} from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage'; // Firebase storage functions
import { useNavigate } from 'react-router-dom'; // For navigating
import { onAuthStateChanged } from 'firebase/auth';

import Header from './Header';
import { FaEye, FaUsers } from 'react-icons/fa'; // Import icons
import { formatDistanceToNow, format } from 'date-fns';
import './UserProfiles.css'; // Our custom overrides
import { ClockIcon, StarIcon, TrophyIcon } from '@heroicons/react/24/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCar,
  faClock,
  faStar,
  faTrophy,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal'; // Reusable Modal component

function UserProfiles() {
  const [profiles, setProfiles] = useState([]);
  const [recentScans, setRecentScans] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [filteredScans, setFilteredScans] = useState([]);
  const [filter, setFilter] = useState('all');
  const [scanStats, setScanStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'activity');
  const [allAchievements, setAllAchievements] = useState([]);
  const [userAchievements, setUserAchievements] = useState([]);
  const [ownedTeams, setOwnedTeams] = useState([]);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [teamToRemove, setTeamToRemove] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthAndFetchData = async () => {
      onAuthStateChanged(auth, async (currentUser) => {
        if (currentUser) {
          setUser(currentUser);
          fetchProfiles(currentUser);
          fetchRecentActivity(currentUser);
          fetchFavorites(currentUser);
          checkAdminRole(currentUser);
          await fetchAllAchievements();
          await fetchUserAchievements(currentUser);
          await fetchOwnedTeams();
        } else {
          navigate('/login');
        }
      });
    };

    const fetchOwnedTeams = async () => {
      if (auth.currentUser) {
        const userId = auth.currentUser.uid;
        try {
          const q = query(collection(db, 'teams'), where('ownerId', '==', userId));
          const querySnapshot = await getDocs(q);
          const teams = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setOwnedTeams(teams);
        } catch (error) {
          console.error('Error fetching owned teams:', error);
        }
      }
    };

    const fetchAllAchievements = async () => {
      try {
        const achievementsQuery = query(
          collection(db, 'achievements'),
          orderBy('NumberOfScansRequired', 'asc')
        );
        const achievementsSnapshot = await getDocs(achievementsQuery);
        const achievementsList = achievementsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAllAchievements(achievementsList);
      } catch (error) {
        console.error('Error fetching achievements:', error);
      }
    };

    const fetchUserAchievements = async (currentUser) => {
      try {
        const docRef = doc(db, 'users', currentUser.uid, 'achievements', 'earned');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserAchievements(docSnap.data().achievements || []);
        }
      } catch (error) {
        console.error('Error fetching user achievements:', error);
      }
    };

    const fetchProfiles = async (currentUser) => {
      try {
        const q = query(
          collection(db, 'profiles'),
          where('userId', '==', currentUser.uid)
        );
        const querySnapshot = await getDocs(q);
        const userProfiles = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch scanning stats for each profile
        const stats = {};
        for (const profile of userProfiles) {
          const statsDoc = await getDoc(doc(db, 'scanStats', profile.id));
          if (statsDoc.exists()) {
            const data = statsDoc.data();
            stats[profile.id] = {
              totalScans: data.totalScans || 0,
              uniqueVisitors: data.uniqueVisitors
                ? Object.keys(data.uniqueVisitors).length
                : 0,
            };
          }
        }

        setProfiles(userProfiles);
        setScanStats(stats);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };

    const fetchFavorites = async (currentUser) => {
      const favoritesDoc = await getDoc(doc(db, 'users', currentUser.uid, 'activity', 'favorites'));
      const fetchProfileData = async (profileId) => {
        const profileDoc = await getDoc(doc(db, 'profiles', profileId));
        return profileDoc.exists() ? { id: profileId, ...profileDoc.data() } : null;
      };

      if (favoritesDoc.exists()) {
        const favoritesList = await Promise.all(
          (favoritesDoc.data().favorites || []).map(async (favorite) => {
            const profile = await fetchProfileData(favorite.id);
            return profile ? { ...profile, timestamp: favorite.timestamp } : null;
          })
        );
        setFavorites(favoritesList.filter((p) => p !== null));
      }
    };

    const fetchRecentActivity = async (currentUser) => {
      try {
        const recentScansDoc = await getDoc(doc(db, 'users', currentUser.uid, 'activity', 'recentScans'));
        const fetchProfileData = async (profileId) => {
          const profileDoc = await getDoc(doc(db, 'profiles', profileId));
          return profileDoc.exists() ? { id: profileId, ...profileDoc.data() } : null;
        };

        if (recentScansDoc.exists()) {
          const scans = await Promise.all(
            (recentScansDoc.data().scans || []).map(async (scan) => {
              const profile = await fetchProfileData(scan.id);
              return profile ? { ...profile, timestamp: scan.timestamp } : null;
            })
          );

          // Keep only the latest scan for each car
          const latestScansMap = scans.reduce((acc, scan) => {
            if (!scan) return acc;
            if (!acc[scan.id] || acc[scan.id].timestamp < scan.timestamp) {
              acc[scan.id] = scan;
            }
            return acc;
          }, {});
          const latestScans = Object.values(latestScansMap);

          setRecentScans(latestScans);
          setFilteredScans(latestScans);
        }
      } catch (error) {
        console.error('Error fetching recent activity:', error);
      }
    };

    const checkAdminRole = async (currentUser) => {
      try {
        const docRef = doc(db, 'roles', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists() && docSnap.data().role === 'admin') {
          setIsAdmin(true);
        }
      } catch (error) {
        console.error('Error checking admin role:', error);
      }
    };

    checkAuthAndFetchData();
  }, [navigate]);

  // Navigation handlers
  const handleEdit = (profileId) => {
    navigate(`/edit-profile/${profileId}`);
  };
  const handleView = (profileId) => {
    navigate(`/car-details/${profileId}`);
  };
  const handleCreateProfile = () => {
    navigate('/create-profile');
  };
  const handleGenerateQR = () => {
    navigate('/admin-generate-qr');
  };
  const handleClearAll = async () => {
    if (!user) return;
    try {
      await updateDoc(doc(db, 'users', user.uid, 'activity', 'recentScans'), { scans: [] });
      setRecentScans([]);
      setFilteredScans([]);
      console.log('Scans cleared successfully.');
    } catch (error) {
      console.error('Error clearing scans:', error);
    }
  };
  const handleRemoveTeamClick = (teamId) => {
    setTeamToRemove(teamId);
    setShowRemoveModal(true);
  };
  const handleConfirmRemove = async () => {
    try {
      if (teamToRemove) {
        await deleteDoc(doc(db, 'teams', teamToRemove));
        setOwnedTeams(ownedTeams.filter((team) => team.id !== teamToRemove));
      }
    } catch (error) {
      console.error('Error removing team:', error);
      alert('There was an issue removing the team. Please try again.');
    } finally {
      setShowRemoveModal(false);
      setTeamToRemove(null);
    }
  };
  const handleRemoveScan = async (scanId) => {
    if (!user) return;
    const updatedScans = recentScans.filter((scan) => scan.id !== scanId);
    try {
      await updateDoc(doc(db, 'users', user.uid, 'activity', 'recentScans'), {
        scans: updatedScans,
      });
      setRecentScans(updatedScans);
      setFilteredScans(updatedScans);
      console.log('Scan removed successfully.');
    } catch (error) {
      console.error('Error removing scan:', error);
    }
  };

  // Filter logic
  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
    const now = new Date();

    const getDateFromTimestamp = (timestamp) => {
      if (timestamp && typeof timestamp.toDate === 'function') {
        return timestamp.toDate();
      }
      return new Date(timestamp);
    };

    let filtered = [];
    switch (selectedFilter) {
      case 'today':
        filtered = recentScans.filter(
          (scan) => getDateFromTimestamp(scan.timestamp).toDateString() === now.toDateString()
        );
        break;
      case 'thisWeek': {
        const weekStart = new Date(now);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // start of week
        filtered = recentScans.filter(
          (scan) => getDateFromTimestamp(scan.timestamp) >= weekStart
        );
        break;
      }
      case 'thisMonth':
        filtered = recentScans.filter((scan) => {
          const scanDate = getDateFromTimestamp(scan.timestamp);
          return (
            scanDate.getMonth() === now.getMonth() &&
            scanDate.getFullYear() === now.getFullYear()
          );
        });
        break;
      case 'last6Months': {
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(now.getMonth() - 6);
        filtered = recentScans.filter(
          (scan) => getDateFromTimestamp(scan.timestamp) >= sixMonthsAgo
        );
        break;
      }
      case 'thisYear':
        filtered = recentScans.filter(
          (scan) =>
            getDateFromTimestamp(scan.timestamp).getFullYear() === now.getFullYear()
        );
        break;
      case 'all':
      default:
        filtered = recentScans;
    }
    setFilteredScans(filtered);
  };

  // Timestamp formatting
  const formatTimestamp = (timestamp) => {
    if (timestamp && typeof timestamp.toDate === 'function') {
      const parsedDate = timestamp.toDate();
      return formatDateOutput(parsedDate);
    }
    if (timestamp instanceof Date) {
      return formatDateOutput(timestamp);
    }
    try {
      const parsedDate = new Date(timestamp);
      if (isNaN(parsedDate.getTime())) {
        console.error('Invalid Date parsed:', timestamp);
        return 'Invalid Date';
      }
      return formatDateOutput(parsedDate);
    } catch (error) {
      console.error('Error parsing timestamp:', error);
      return 'Invalid Date';
    }
  };
  const formatDateOutput = (parsedDate) => {
    const now = new Date();
    if (parsedDate.toDateString() === now.toDateString()) {
      return formatDistanceToNow(parsedDate, { addSuffix: true });
    }
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (parsedDate.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    }
    return format(parsedDate, 'MMMM d, yyyy, h:mm a');
  };

  const isAchievementEarned = (achievementId) => {
    return userAchievements.includes(achievementId);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    localStorage.setItem('activeTab', tab);
  };

  return (
    <div className="flex flex-col h-screen font-body">
      {/* Header */}
      <div className="flex-none">
        <Header />
      </div>

      {/* Main content area */}
      <div className="flex-grow bg-white text-primary relative">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <div className="loader">Loading...</div>
          </div>
        ) : (
          <>
            {/* Bottom Navigation */}
            <div className="fixed bottom-0 w-full p-2 flex justify-around bg-neutral-900 text-gray-400 z-20">
              <button
                onClick={() => handleTabChange('activity')}
                className={`flex flex-col items-center ${
                  activeTab === 'activity' ? 'text-accent' : ''
                }`}
              >
                <FontAwesomeIcon icon={faClock} className="h-6 w-6" />
                <span className="text-xs mt-1">Activity</span>
              </button>
              <button
                onClick={() => handleTabChange('favorites')}
                className={`flex flex-col items-center ${
                  activeTab === 'favorites' ? 'text-accent' : ''
                }`}
              >
                <FontAwesomeIcon icon={faStar} className="h-6 w-6" />
                <span className="text-xs mt-1">Favorites</span>
              </button>
              <button
                onClick={() => handleTabChange('profiles')}
                className={`flex flex-col items-center ${
                  activeTab === 'profiles' ? 'text-accent' : ''
                }`}
              >
                <FontAwesomeIcon icon={faCar} className="h-6 w-6" />
                <span className="text-xs mt-1">Profiles</span>
              </button>
              <button
                onClick={() => handleTabChange('teams')}
                className={`flex flex-col items-center ${
                  activeTab === 'teams' ? 'text-accent' : ''
                }`}
              >
                <FontAwesomeIcon icon={faUsers} className="h-6 w-6" />
                <span className="text-xs mt-1">Teams</span>
              </button>
              <button
                onClick={() => handleTabChange('achievements')}
                className={`flex flex-col items-center ${
                  activeTab === 'achievements' ? 'text-accent' : ''
                }`}
              >
                <FontAwesomeIcon icon={faTrophy} className="h-6 w-6" />
                <span className="text-xs mt-1">Achievements</span>
              </button>
            </div>

            {/* Content */}
            <div className="min-h-screen bg-white text-primary p-4 pb-20">
              {activeTab === 'achievements' && (
                <div>
                  <h2 className="text-2xl font-heading font-bold mb-4">Achievements</h2>
                  <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 w-full">
                    {allAchievements.map((achievement) => (
                      <div
                        key={achievement.id}
                        className={`p-4 rounded-lg border border-gray-200 text-center ${
                          isAchievementEarned(achievement.id) ? '' : 'opacity-100'
                        }`}
                      >
                        <img
                          src={achievement.icon}
                          alt={achievement.name}
                          className="mx-auto mb-2"
                          style={{ width: '64px', height: 'auto' }}
                          title={achievement.description}
                        />
                        <div className="flex flex-col items-center">
                          <p className="text-sm font-semibold min-h-[40px]">
                            {achievement.name}
                          </p>
                          <p
                            className={`text-sm mt-1 ${
                              isAchievementEarned(achievement.id)
                                ? 'text-green-500'
                                : 'text-red-500'
                            }`}
                          >
                            {isAchievementEarned(achievement.id) ? 'Earned' : 'Locked'}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {activeTab === 'profiles' && (
                <div className="relative">
                  <div className="sticky top-0 bg-white py-4 z-10 border-b border-gray-200 flex justify-center">
                    <button
                      onClick={handleCreateProfile}
                      className="bg-accent text-white px-4 py-2 rounded-lg hover:bg-yellow-600 mr-4"
                    >
                      Create New Profile
                    </button>
                    {isAdmin && (
                      <button
                        onClick={handleGenerateQR}
                        className="bg-accent text-white px-4 py-2 rounded-lg hover:bg-yellow-600"
                      >
                        Admin: Generate QR Codes
                      </button>
                    )}
                  </div>

                  <div
                    className="overflow-y-auto mt-4"
                    style={{ maxHeight: 'calc(100vh - 350px)' }}
                  >
                    {profiles.length > 0 ? (
                      <div className="grid grid-cols-1 gap-4">
                        {profiles.map((profile) => {
                          const firstCarImage =
                            profile.carPictures?.length > 0
                              ? profile.carPictures[0]
                              : profile.carPicture;
                          return (
                            <a
                              href={`/car-details/${profile.id}`}
                              className="mt-1 inline-block"
                              key={profile.id}
                            >
                              <div className="bg-white rounded-lg p-4 border border-gray-200 flex flex-col items-start hover:shadow-lg transition-shadow">
                                <h3 className="text-xl font-heading font-semibold mb-2">
                                  {profile.carModel}
                                </h3>
                                <div className="flex items-center">
                                  <img
                                    src={firstCarImage}
                                    alt="Car Thumbnail"
                                    className="w-20 h-20 object-cover rounded mr-4"
                                    onError={(e) =>
                                      (e.target.src = `${process.env.PUBLIC_URL}/RaceCarPlaceholder.png`)
                                    }
                                  />
                                  <div className="flex-1">
                                    <p className="text-sm">Driver: {profile.driverName}</p>
                                    {scanStats[profile.id] && (
                                      <div className="mt-2 flex items-center space-x-4 text-xs">
                                        <div className="flex items-center">
                                          <FaEye className="text-accent mr-1" />
                                          <span>{scanStats[profile.id].totalScans || 0} Views</span>
                                        </div>
                                        <div className="flex items-center">
                                          <FaUsers className="text-accent mr-1" />
                                          <span>
                                            {scanStats[profile.id].uniqueVisitors || 0} Unique Visitors
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </a>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="text-lg text-center mt-4">
                        You haven't created any car profiles yet. Click Create New Profile above to
                        add one!
                      </p>
                    )}
                  </div>
                </div>
              )}

              {activeTab === 'teams' && (
                <div>
                  <h2 className="text-2xl font-heading font-bold mb-4">Your Teams</h2>
                  <button
                    onClick={() => navigate('/create-team')}
                    className="bg-accent text-white px-4 py-2 rounded-lg hover:bg-yellow-600"
                  >
                    Create New Team
                  </button>
                  {ownedTeams.length > 0 ? (
                    <div className="grid grid-cols-1 gap-4 mt-4">
                      {ownedTeams.map((team) => (
                        <div
                          key={team.id}
                          className="bg-white rounded-lg p-4 border border-gray-200"
                        >
                          <h3 className="text-lg font-heading font-semibold">{team.teamName}</h3>
                          <p>{team.teamDescription || 'No description available.'}</p>
                          <button
                            onClick={() => navigate(`/team-management/${team.id}`)}
                            className="mt-2 bg-accent text-white px-3 py-1 rounded-lg hover:bg-yellow-600"
                          >
                            Manage Team
                          </button>
                          <button
                            onClick={() => handleRemoveTeamClick(team.id)}
                            className="mt-2 bg-red-600 text-white px-3 py-1 rounded-lg hover:bg-red-700 ml-2"
                          >
                            Remove Team
                          </button>
                        </div>
                      ))}
                      {showRemoveModal && (
                        <Modal
                          title="Confirm Removal"
                          onClose={() => setShowRemoveModal(false)}
                          onConfirm={handleConfirmRemove}
                          confirmText="Remove"
                          cancelText="Cancel"
                        >
                          <p>Are you sure you want to remove this team? This cannot be undone.</p>
                        </Modal>
                      )}
                    </div>
                  ) : (
                    <p className="mt-4">You do not own any teams.</p>
                  )}
                </div>
              )}

              {activeTab === 'activity' && (
                <div>
                  <h2 className="text-2xl font-heading font-bold mb-4">Your Activity</h2>
                  {recentScans.length > 0 && (
                    <div className="flex items-center space-x-4 mb-4">
                      <select
                        onChange={(e) => handleFilterChange(e.target.value)}
                        value={filter}
                        className="px-3 py-2 rounded-lg bg-neutral-800 text-white focus:outline-none focus:ring-2 focus:ring-accent"
                      >
                        <option value="today">Today</option>
                        <option value="thisWeek">This Week</option>
                        <option value="thisMonth">This Month</option>
                        <option value="last6Months">Last 6 Months</option>
                        <option value="thisYear">This Year</option>
                        <option value="all">All</option>
                      </select>

                      <button
                        onClick={handleClearAll}
                        className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700"
                      >
                        Clear All Activity
                      </button>
                    </div>
                  )}

                  {filteredScans.length > 0 ? (
                    <div className="grid grid-cols-1 gap-4">
                      {filteredScans.map((scan, index) => {
                        const firstCarImage =
                          scan.carPictures?.length > 0
                            ? scan.carPictures[0]
                            : scan.carPicture;
                        return (
                          <a
                            href={`/car-details/${scan.id}`}
                            key={index}
                            className="mt-1 inline-block"
                          >
                            <div className="bg-white rounded-lg p-4 border border-gray-200 flex flex-col items-start hover:shadow-lg transition-shadow">
                              <h4 className="text-lg font-heading font-semibold mb-2">
                                {scan.carModel}
                              </h4>
                              <div className="flex items-center">
                                <img
                                  src={firstCarImage}
                                  alt="Car Thumbnail"
                                  className="w-20 h-20 object-cover rounded mr-4"
                                />
                                <div>
                                  <p className="text-sm">Driver: {scan.driverName}</p>
                                  <p className="text-sm pt-2">
                                    Viewed {formatTimestamp(scan.timestamp)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="text-sm">No activity yet.</p>
                  )}
                </div>
              )}

              {activeTab === 'favorites' && (
                <div className="mb-8">
                  <h2 className="text-2xl font-heading font-bold mb-4">Your Favorites</h2>
                  {favorites.length > 0 ? (
                    <div className="grid grid-cols-1 gap-4">
                      {favorites.map((fav, index) => {
                        const firstCarImage =
                          fav.carPictures?.length > 0 ? fav.carPictures[0] : fav.carPicture;
                        return (
                          <a
                            href={`/car-details/${fav.id}`}
                            key={index}
                            className="mt-1 inline-block"
                          >
                            <div className="bg-white rounded-lg p-4 border border-gray-200 flex flex-col items-start hover:shadow-lg transition-shadow">
                              <h4 className="text-lg font-heading font-semibold mb-2">
                                {fav.carModel}
                              </h4>
                              <div className="flex items-center">
                                <img
                                  src={firstCarImage}
                                  alt="Car Thumbnail"
                                  className="w-20 h-20 object-cover rounded mr-4"
                                />
                                <div>
                                  <p className="text-sm">Driver: {fav.driverName}</p>
                                </div>
                              </div>
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="text-sm">No saved favorites.</p>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default UserProfiles;
